import * as React from "react";
import type { HeadFC } from "gatsby";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import logomark from "../images/logomark.svg";
import Highlight from "../components/highlight/highlight";

const PrivacyPage = () => {
    return (
        <div className={"overflow-x-hidden"}>
            <Navbar />
            <main className={"relative z-0 dark:text-stone-50/75"}>
                <div className={"px-8 pt-16 pb-16 text-center md:container md:mx-auto"}>
                    <h1 className={"text-4xl font-bold text-stone-800 dark:text-stone-300 md:text-6xl"}>
                        Maildrop Privacy Policy
                    </h1>
                </div>
                <div className={"px-8 pb-32 md:container md:mx-auto md:flex md:justify-center"}>
                    <div className={"md:flex-none md:basis-[50ch]"}>
                        <p className={"pb-8"}>
                            <b className={"font-bold"}>Please note</b>: you should have no expectation of privacy when
                            sending messages to your Maildrop inbox.
                        </p>
                        <p className={"pb-8"}>
                            <Highlight>Any message sent to Maildrop can be read by any user.</Highlight>
                        </p>
                        <p className={"pb-8"}>Every inbox on Maildrop is available to the public.</p>
                        <p>
                            There are - by design - no security measures to sign into Maildrop and view email messages.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2
                            data-cypress={"sensitive"}
                            className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Do not send sensitive information to Maildrop.
                        </h2>
                        <p className={"relative pb-8"}>
                            Please do not send any information to Maildrop that you would consider personal or private.
                            This includes your home address, your phone number, or{" "}
                            <Highlight>any other personal information</Highlight>. Even if you are constantly monitoring
                            the Maildrop inbox you gave to the third party website/app, it is extremely likely that
                            someone else could be watching that inbox as well.
                        </p>
                        <p>
                            While it may be unlikely that someone can guess a random inbox, there is no guarantee that
                            other people don't have access to your email messages. Please treat Maildrop as if someone
                            else were watching over your shoulder at all times.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Maildrop inbox aliases are not secure.
                        </h2>
                        <p className={"pb-8"}>
                            While inbox aliases are meant to be a layer of obfuscation to the real email address at
                            maildrop.cc, you should be aware that the algorithm which converts from real addresses to
                            inbox aliases, and vice versa, is public, and can be easily reverse engineered by a
                            determined individual.
                        </p>
                        <p>
                            Inbox aliases are fine for sending random website/app emails to, but again, please do not
                            send any private, personal, confidential messages to an inbox alias; it is extremely likely
                            that someone can and will figure out the true inbox that the message is delivered to.
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            Connections to Maildrop are logged.
                        </h2>
                        <p className={"pb-8"}>
                            In order to help refine the service, all email and web connections to Maildrop are logged.
                        </p>
                        <p className={"pb-8"}>
                            <Highlight>Maildrop is not designed to be completely anonymous</Highlight> and is not
                            designed to be an alternative to a re-mailer. There are plenty of anonymous re-mailers
                            available on the Internet; Maildrop is not one of them.
                        </p>
                        <p>
                            The debugging and connection information that we log is helpful to us, but we cannot provide
                            end users with any information about "what happened to my email".
                        </p>

                        <div className={"flex justify-center pt-16"}>
                            <img
                                src={logomark}
                                className={"w-12 opacity-5 dark:opacity-10 dark:invert"}
                                alt={"Maildrop logo"}
                            />
                        </div>

                        <h2 className={"pb-8 pt-16 text-2xl font-bold text-stone-800 dark:text-stone-200 md:text-4xl"}>
                            We do not track our visitors.
                        </h2>
                        <p className={"pb-8"}>
                            Finally, the only storage we use is to keep track of the most recent inbox that you've
                            visited. We do not track our users, nor do we use cookies to keep personal information.
                        </p>

                        <p>
                            <Highlight>We do not collect any information</Highlight> about our end users beyond their
                            email and web connections to maildrop.cc and only use our analytics pixel to collect
                            aggregate data.
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export const Head: HeadFC = () => (
    <>
        <title>Privacy Policy | Maildrop</title>
    </>
);

export default PrivacyPage;
