import * as React from "react";
import { useInView } from "react-intersection-observer";

interface HighlightProps {
    children: React.ReactNode;
}

const Highlight = (props: HighlightProps) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "-100px 0px",
        fallbackInView: true
    });
    return (
        <span ref={ref} className={`highlight ${inView ? "run" : ""} relative -z-10 inline-block`}>
            {props.children}
        </span>
    );
};

export default Highlight;
